.rsa-page {
  [stripe-contacts] {
    @include media-breakpoint-down(lg) {
      margin-top: 2.222rem !important;

      .contacts {
        margin-bottom: 2.222rem !important;
      }
    }

    .card {
      cursor: auto;
      &-body {
        @include font-size(1rem);
      }

      &-title {
        @include font-size(1.5rem);
        margin-bottom: 1rem;
      }

      @include media-breakpoint-up(sm) {
        &-body {
          padding: 1.666rem !important;
        }
      }
    }
  }

  [stripe-direction] {
    .card {
      &-header {
        display: flex;
        align-items: center;

        span {
          @include font-size(1rem);
          font-weight: 600;
        }
      }
    }

    .map-container {
      height: 50vh;

      iframe {
        height: 100%;
      }
    }
  }

  [stripe-photo-carousel] {
    .parent {
      display: grid;
      grid-template-columns: 59% 38%;
      grid-template-rows: repeat(18, 32px);
      grid-column-gap: 32px;
      grid-row-gap: 0px;

      .div1 { grid-area: 2 / 1 / 12 / 2; }
      .div2 { grid-area: 1 / 2 / 14 / 3; }
      .div3 { 
        grid-area: 13 / 1 / 19 / 2;
        
        > div {
          width: 60%;
          height: 100%;
          float: right;
        }
      }
    }

    .flickity-page-dots {
      bottom: -45px;
    }

    @include media-breakpoint-down(lg) {
      .img-fluid {
        width: auto;
        max-height: 25vh;
      }
    }

    #photo-modal {
      .img-fluid {
        max-width: 90%;
        height: 50vh;
      }

      .flickity-prev-next-button {
        color: var(--bs-primary);
  
        &.previous {
          left: 10px;
        }
  
        &.next {
          right: 10px;
        }
      }
    }
  }

  [stripe-visit] {
    @include media-breakpoint-down(sm) {
      .ratio-21x9 {
        --bs-aspect-ratio: 56.25%; // 16x9
      }
    }
  }
}