.side-navbar-open {
  overflow: hidden;
}

#main-navbar { 
  top: $top-header-height;

  @include media-breakpoint-up(md) {
    &.main-navbar-en {
      top: 0;
    }
  }

  > nav {
    height: $header-height;
    z-index: $zindex-sticky + 1;
    background-color: $white;

    @include media-breakpoint-down(xl) {
      height: $header-height-sm;
    }

    .dropdown-menu {
      columns: 2;
      border-radius: 0;
      border: none !important;
      margin-top: 0 !important;

      .dropdown-item {
        line-height: 1.75rem;
      }
    }

    .hamburger {
      font-size: 2rem;
      height: $header-height-sm;
      width: $header-height-sm;
    }

    .nav-item {
      margin-top: 7px;
    }

    .nav-item, .dropdown-menu {
      a {
        text-decoration: none;
        transition: color .25s;
        font-size: $small-font-size !important;
        color: $primary;
      }

      &:hover > a,
      &:active > a,
      &:focus > a,
      &.active > a 
      & > .submenu a:hover {
        color: $cerulean;
      }
    }

    .navbar-brand {
      line-height: 1rem;
    }

    .navbar-logo {
      width: auto;
      height: 55px;

      @include media-breakpoint-down(xl) {
        height: 40px;
      }

      &-secondary {
        height: 21px;
      }
    }
  }
  
}

#side-navbar {
  top: calc(#{$top-header-height} + #{$header-height-sm});
  width: 100vw;
  height: calc(100vh - #{$top-header-height} - #{$header-height-sm});
  left: -100vw;
  z-index: 1020;
  transition: left .15s linear;
  overflow-y: scroll;

  &.active {
    left: 0;
  }

  #subside-navbar-main {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 1.5rem;
      }
    }
  }

  .subside-navbar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: $header-height-sm;
    font-size: 1.5rem;
    cursor: pointer;
  }

  li {
    height: $header-height-sm;
    border-top: 1px solid $primary;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: 1px solid $primary;
    }
  }
}

#top-navbar {
  &, .h-header {
    height: $top-header-height;
  }

  @include media-breakpoint-down(md) {
    .navbar-nav { width: 100%; }
  }

  .nav-item {
    font-size: .75rem !important;
    font-weight: 400;

    img {
      width: 25px;

      &.radio-icon {
        width: 15px;
      }
    }

    a {
      text-decoration: none;
    }

  }
}