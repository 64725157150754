$formats: ("woff2": "woff2", "woff": "woff");
$icon-formats: ("ttf": "truetype", "woff": "woff", "svg": "svg");

// Generates the font-face for a font
@mixin gen-font-face($family, $src, $weight, $style, $display) {
  @font-face {
    src: $src;
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    font-display: $display;
  }
}


@mixin gen-font($family, $font, $weight: normal, $style: normal, $display: auto, $is-icon: false) {
  $src: null;
  $formatsList: $formats;
  
  @if $is-icon {
    $formatsList: $icon-formats;
  }
  
  @each $format, $mime in $formatsList {
    $src: append($src, url("../assets/fonts/#{$font}.#{$mime}") format("#{$format}"), comma);
  }
  @include gen-font-face($family, $src, $weight, $style, $display);
}