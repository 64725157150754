// mixin
@import "mixin";

// bootstrap
@import "custom";
@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

// font
@import "icon_variables";
@import "font";

@import "../node_modules/highlight.js/scss/default";
@import "../node_modules/flickity/css/flickity";
@import "clipboard";

// components
@import "navbar";
@import "footer";
@import "one-col";
@import "carousel";
@import "card";
@import "accordion";
@import "quote";
@import "breadcrumb";
@import "price";
@import "internal-menu";

// page
@import "home";
@import "rsa";
@import "region";
@import "la-nostra-equipe";
@import "le-nostre-soluzioni";
@import "soluzioni-cure";
@import "alzheimer";
@import "patologie";
@import "soluzioni-residenziale";
@import "contatti";
@import "cure-avanzate";
@import "news-content";

html {
  font-size: 18px;
}

p {
  @include font-size(1rem);
}

hr {
  border-bottom: .5px solid $dark-brown;
}

.btn {
  text-transform: uppercase;
}

.position-absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-cover-center {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-contain-center {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.font-sign {
  font-family: 'LobstersBlush', Courier, monospace;
}

.font-weight-600 {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

#content-anchor {
  width: 100%;
  position: absolute;
  top: calc((#{$header-height} + #{$top-header-height}) * -1);
  height: calc(#{$header-height} + #{$top-header-height});

  @include media-breakpoint-down(xl) {
    top: calc((#{$header-height-sm} + #{$top-header-height}) * -1);
    height: calc(#{$header-height-sm} + #{$top-header-height});
  }
}