.flickity {
  &-slider {
    display: flex;
  }

  &-page-dots {
    .is-selected {
      background-color: var(--bs-azure);
    }

    .dot {
      width: 13px;
      height: 13px;

      @include media-breakpoint-down(md) {
        width: 10px;
        height: 10px;
      }
    }
  }

  &-prev-next-button {
    background: transparent;
    color: $white;
    
    &:hover {
      background: transparent;
    }

    &.next {
      right: -40px;
    }

    &.previous {
      left: -50px;
    }
  }

  @include media-breakpoint-down(md) {
    &-button {
      display: none;
    }
  }
}

.carousel {
  &-cell {
    width: 25%;
    padding-right: 10px;

    @include media-breakpoint-down(md) {
      width: 80%;
      padding: 0 10px;
    }
  }

  &-full {
    .next {
      right: 10px;
    }

    .previous {
      left: 10px;
    }

    .flickity-page-dots {
      bottom: 10px;

      .dot:not(.is-selected) {
        background-color: $white;
        opacity: 1;
      }
    }
  }
}

[carousel-region] {
  .carousel {
    &-cell {
      width: 33.3333%;
      padding-right: 0;
      padding-left: 1.8rem;
    }

    @include media-breakpoint-down(xl) {
      &-cell {
        width: 40%;
        padding-right: 1rem;
        padding-left: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      &-cell {
        width: 37%;
        padding-right: 1rem;
        padding-left: 0;
      }
    }

    @include media-breakpoint-down(md) {
      &-cell {
        width: 70%;
        padding-right: 1rem;
        padding-left: 0;
      }
    }
  }

  .flickity {
    &-page-dots {
      bottom: -38px;
    }

    @include media-breakpoint-up(md) {
      &-page-dots {
        display: none;
      }
    }

    &-prev-next-button {
      color: $primary;
      transform: none;
      top: auto;
      bottom: -3.5rem;
      
      
      &.next {
        right: 0;
        padding-right: 0;
      }

      &.previous {
        right: 5rem;
        left: auto;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .carousel-container {
      padding-right: 0; 
    }
  }
}

[carousel-news] {
  .carousel {
    &-cell {
      width: 33.3333%;

      .card {
        height: calc(100% - 4px);

        &-title, &-text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
    
        &-text {
          -webkit-line-clamp: 4;
        }
      }
    }

    @include media-breakpoint-down(md) {
      &-cell {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .flickity {
    &-page-dots {
      bottom: -38px;
    }

    @include media-breakpoint-up(md) {
      &-page-dots {
        display: none;
      }
    }

    &-prev-next-button {
      display: none;
    }
  }
}