.le-nostre-soluzioni-page {

  .parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .div1 { grid-area: 1 / 1 / 3 / 3; }
  .div2 { grid-area: 1 / 3 / 4 / 5; }
  .div3 { grid-area: 3 / 1 / 6 / 3; }
  .div4 { grid-area: 4 / 3 / 6 / 5; }

  @include media-breakpoint-down(md) {
    .parent {
      display: flex;
      flex-wrap: wrap;
      grid-row-gap: 0; // necessary
    }

    .div1, .div2, .div3, .div4 {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

}