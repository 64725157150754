@include gen-font("Open Sans", "OpenSans", normal);
@include gen-font("Open Sans", "OpenSans-Bold", bold);
@include gen-font("Open Sans", "OpenSans-Semibold", 600);

@include gen-font("LobstersBlush", "LobstersBlushBold", bold);

// icomoon
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../assets/fonts" !default;

@include gen-font($icomoon-font-family, $icomoon-font-family, normal, normal, block, true);

.ki {
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: $blue-secondary;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ki-home {
  &:before {
    content: $ki-home; 
  }
}
.ki-check {
  &:before {
    content: $ki-check; 
  }
}
.ki-phone {
  &:before {
    content: $ki-phone; 
  }
}
.ki-euro {
  &:before {
    content: $ki-euro; 
  }
}
.ki-terapist-phy {
  &:before {
    content: $ki-terapist-phy; 
  }
}
.ki-director {
  &:before {
    content: $ki-director; 
  }
}
.ki-doctor-phy {
  &:before {
    content: $ki-doctor-phy; 
  }
}
.ki-equipe {
  &:before {
    content: $ki-equipe; 
  }
}
.ki-health-director {
  &:before {
    content: $ki-health-director; 
  }
}
.ki-healthcare-principal {
  &:before {
    content: $ki-healthcare-principal; 
  }
}
.ki-healthcare {
  &:before {
    content: $ki-healthcare; 
  }
}
.ki-internal-menu {
  &:before {
    content: $ki-internal-menu;
  }
}
.ki-terapie-animazione {
  &:before {
    content: $ki-terapie-animazione; 
  }
}
.ki-terapie-attivita-in-giardino {
  &:before {
    content: $ki-terapie-attivita-in-giardino; 
  }
}
.ki-terapie-attivita-sportiva {
  &:before {
    content: $ki-terapie-attivita-sportiva; 
  }
}
.ki-terapie-auto {
  &:before {
    content: $ki-terapie-auto; 
  }
}
.ki-terapie-autosufficienti {
  &:before {
    content: $ki-terapie-autosufficienti; 
  }
}
.ki-terapie-cineforum {
  &:before {
    content: $ki-terapie-cineforum; 
  }
}
.ki-terapie-clownterapia {
  &:before {
    content: $ki-terapie-clownterapia; 
  }
}
.ki-terapie-compleanno {
  &:before {
    content: $ki-terapie-compleanno; 
  }
}
.ki-terapie-cucina {
  &:before {
    content: $ki-terapie-cucina; 
  }
}
.ki-terapie-elioterapia {
  &:before {
    content: $ki-terapie-elioterapia; 
  }
}
.ki-terapie-fotografia {
  &:before {
    content: $ki-terapie-fotografia; 
  }
}
.ki-terapie-ginnastica-di-gruppo {
  &:before {
    content: $ki-terapie-ginnastica-di-gruppo; 
  }
}
.ki-terapie-giochi-di-stimolazione-cognitiva {
  &:before {
    content: $ki-terapie-giochi-di-stimolazione-cognitiva; 
  }
}
.ki-terapie-gruppo-lettura {
  &:before {
    content: $ki-terapie-gruppo-lettura; 
  }
}
.ki-terapie-laboratorio-artistico {
  &:before {
    content: $ki-terapie-laboratorio-artistico; 
  }
}
.ki-terapie-lavori-manuali {
  &:before {
    content: $ki-terapie-lavori-manuali; 
  }
}
.ki-terapie-messa {
  &:before {
    content: $ki-terapie-messa; 
  }
}
.ki-terapie-mostre {
  &:before {
    content: $ki-terapie-mostre; 
  }
}
.ki-terapie-musica-dal-vivo-e-ballo {
  &:before {
    content: $ki-terapie-musica-dal-vivo-e-ballo; 
  }
}
.ki-terapie-musicoterapia {
  &:before {
    content: $ki-terapie-musicoterapia; 
  }
}
.ki-terapie-ortoterapia-ortobotanica {
  &:before {
    content: $ki-terapie-ortoterapia-ortobotanica; 
  }
}
.ki-terapie-pet {
  &:before {
    content: $ki-terapie-pet; 
  }
}
.ki-terapie-psicologo {
  &:before {
    content: $ki-terapie-psicologo; 
  }
}
.ki-terapie-scarica-file {
  &:before {
    content: $ki-terapie-scarica-file; 
  }
}
.ki-terapie-teatro {
  &:before {
    content: $ki-terapie-teatro; 
  }
}
.ki-terapie-treno {
  &:before {
    content: $ki-terapie-treno; 
  }
}
.ki-terapie-universita-della-terza-eta {
  &:before {
    content: $ki-terapie-universita-della-terza-eta; 
  }
}
.ki-terapie-yoga {
  &:before {
    content: $ki-terapie-yoga; 
  }
}
.ki-servizi-sanitari-adi-post-dimissione {
  &:before {
    content: $ki-servizi-sanitari-adi-post-dimissione; 
  }
}
.ki-servizi-sanitari-alzheimer-nucleo {
  &:before {
    content: $ki-servizi-sanitari-alzheimer-nucleo; 
  }
}
.ki-servizi-sanitari-autosufficienti {
  &:before {
    content: $ki-servizi-sanitari-autosufficienti; 
  }
}
.ki-servizi-sanitari-centro-dialisi {
  &:before {
    content: $ki-servizi-sanitari-centro-dialisi; 
  }
}
.ki-servizi-sanitari-centro-diurno {
  &:before {
    content: $ki-servizi-sanitari-centro-diurno; 
  }
}
.ki-servizi-sanitari-cure-domiciliari {
  &:before {
    content: $ki-servizi-sanitari-cure-domiciliari; 
  }
}
.ki-servizi-sanitari-cure-intermedie {
  &:before {
    content: $ki-servizi-sanitari-cure-intermedie; 
  }
}
.ki-servizi-sanitari-demenza-nucleo-protetto {
  &:before {
    content: $ki-servizi-sanitari-demenza-nucleo-protetto; 
  }
}
.ki-servizi-sanitari-demenza {
  &:before {
    content: $ki-servizi-sanitari-demenza; 
  }
}
.ki-servizi-sanitari-dentista {
  &:before {
    content: $ki-servizi-sanitari-dentista; 
  }
}
.ki-servizi-sanitari-educadore-prof {
  &:before {
    content: $ki-servizi-sanitari-educadore-prof; 
  }
}
.ki-servizi-sanitari-guardia-medica-h24 {
  &:before {
    content: $ki-servizi-sanitari-guardia-medica-h24; 
  }
}
.ki-servizi-sanitari-hospice {
  &:before {
    content: $ki-servizi-sanitari-hospice; 
  }
}
.ki-servizi-sanitari-infermiere-h24 {
  &:before {
    content: $ki-servizi-sanitari-infermiere-h24; 
  }
}
.ki-servizi-sanitari-medico-h24 {
  &:before {
    content: $ki-servizi-sanitari-medico-h24; 
  }
}
.ki-servizi-sanitari-non-autosufficienti {
  &:before {
    content: $ki-servizi-sanitari-non-autosufficienti; 
  }
}
.ki-servizi-sanitari-operatore {
  &:before {
    content: $ki-servizi-sanitari-operatore; 
  }
}
.ki-servizi-sanitari-palestra-fisioterapia {
  &:before {
    content: $ki-servizi-sanitari-palestra-fisioterapia; 
  }
}
.ki-servizi-sanitari-palestra {
  &:before {
    content: $ki-servizi-sanitari-palestra; 
  }
}
.ki-servizi-sanitari-parzialmente-autosufficienti {
  &:before {
    content: $ki-servizi-sanitari-parzialmente-autosufficienti; 
  }
}
.ki-servizi-sanitari-polo-diagnostico {
  &:before {
    content: $ki-servizi-sanitari-polo-diagnostico; 
  }
}
.ki-servizi-sanitari-ps-disabili {
  &:before {
    content: $ki-servizi-sanitari-ps-disabili; 
  }
}
.ki-servizi-sanitari-ps-gravi-disabilita {
  &:before {
    content: $ki-servizi-sanitari-ps-gravi-disabilita; 
  }
}
.ki-servizi-sanitari-ps-psichiatrici {
  &:before {
    content: $ki-servizi-sanitari-ps-psichiatrici; 
  }
}
.ki-servizi-sanitari-riabilitazione-general-geriatrica {
  &:before {
    content: $ki-servizi-sanitari-riabilitazione-general-geriatrica; 
  }
}
.ki-servizi-sanitari-riabilitazione-mantenimento {
  &:before {
    content: $ki-servizi-sanitari-riabilitazione-mantenimento; 
  }
}
.ki-servizi-sanitari-riabilitazione-specialistica {
  &:before {
    content: $ki-servizi-sanitari-riabilitazione-specialistica; 
  }
}
.ki-servizi-sanitari-ricoveri-temporanei {
  &:before {
    content: $ki-servizi-sanitari-ricoveri-temporanei; 
  }
}
.ki-servizi-sanitari-stati-vegetativi {
  &:before {
    content: $ki-servizi-sanitari-stati-vegetativi; 
  }
}
.ki-flag-en {
  &:before {
    content: $ki-flag-en; 
  }
}
.ki-confort-bar {
  &:before {
    content: $ki-confort-bar; 
  }
}
.ki-confort-camera-singola {
  &:before {
    content: $ki-confort-camera-singola; 
  }
}
.ki-confort-cappella {
  &:before {
    content: $ki-confort-cappella; 
  }
}
.ki-confort-estetista {
  &:before {
    content: $ki-confort-estetista; 
  }
}
.ki-confort-giardino {
  &:before {
    content: $ki-confort-giardino; 
  }
}
.ki-confort-giornali {
  &:before {
    content: $ki-confort-giornali; 
  }
}
.ki-confort-parrucchiera {
  &:before {
    content: $ki-confort-parrucchiera; 
  }
}
.ki-confort-podologo {
  &:before {
    content: $ki-confort-podologo; 
  }
}
.ki-confort-terrazzo {
  &:before {
    content: $ki-confort-terrazzo; 
  }
}