.patologie-page {
  [stripe-grid] {
    .card {
      &-body{
        @include padding(2.2rem);

        @include media-breakpoint-down(xl) {
          @include padding(1.5rem);
        }
      }
    }
  }
}