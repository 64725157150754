.bd-clipboard button {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 10;
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: .65em;
  color: $primary;
  background-color: #fff;
  border: 1px solid;
  border-radius: 0.25rem;
}