.cure-avanzate-page {
  [stripe-keypoints] {
    > .row {
      --bs-gutter-x: 5rem;
    }
    
    .col.border-left {
      border-left: .5px solid $dark-brown;

      @include media-breakpoint-down(lg) {
        border: 0;
      }
    }

    .col-auto {
      border: 3px solid var(--bs-azure);
      border-radius: 50%;
      width: 7rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;

      .ki {
        font-size: 3rem;
      }

      @include media-breakpoint-down(lg) {
        width: 4rem;
        height: 4rem;
        
        .ki {
          font-size: 2rem;
        }
      }
    }
  }
}