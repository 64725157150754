.accordion {
  &-item {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  &-button {
    background-color: $white !important;
    box-shadow: none !important;
    border-radius: 0;
    border: 0 !important;

    &::after {
      position: absolute;
      top: 1rem;
      right: 0;
    }
  
    &-text {
      @include font-size(2rem);
      flex: 1;
    }
  
    &-helper {
      position: absolute;
      right: 0;
      top: 1rem;
      font-size: $font-size-base;
      margin-right: 2rem;
    }

    &.collapsed {
      .show-less {
        display: none;
      }
      .show-more {
        display: block;
      }
    }

    &:not(.collapsed) {
      .show-less {
        display: block;
      }
      .show-more {
        display: none;
      }
    }
  }

  &-body {
    @include font-size(1rem);

    .card {
      cursor: auto;
    }

    .icon-list {
      @include font-size(1.5rem);
      color: $primary;
      display: flex;
      align-items: baseline;
      padding-top: .5rem;
      padding-bottom: .5rem;

      @include media-breakpoint-up(lg) {
        min-height: 100px;
      }
    }
  }
}