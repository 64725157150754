.soluzioni-residenziale-page {
  [stripe-grid] {
    .card {
      &-body{
        @include padding(2.2rem);

        @include media-breakpoint-down(xl) {
          @include padding(1.5rem);
        }
      }
    }
  }

  [stripe-map] {
    .map-container {
      @include media-breakpoint-down(md) {
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));

        .ratio-4x3 {
          --bs-aspect-ratio: 100%; // 1x1
        }
      }
    }
  }
}