.region-page {
  [stripe-region-map] {
    .rsa-group {
      &:last-child {
        padding-bottom: 0 !important;

        a:last-child .card-rsa {
          margin-bottom: 0 !important;
        }
      }
    }

    .map-container {
      @include media-breakpoint-up(lg) {
        position: sticky;
        top: calc(#{$header-height} + #{$top-header-height});
        z-index: $zindex-sticky - 1;
        height: fit-content; 
      }

      @include media-breakpoint-only(md) {
        .ratio-1x1 {
          --bs-aspect-ratio: 50%; // 2x1
        }
      }

      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  [stripe-faq] {
    .accordion {
      &-item {
        .container {
          padding: 0;
        }
      }

      &-button {
        &::after, &-helper {
          position: relative;
          top: auto;
        }

        &-text {
          @include font-size(1.22rem);
          font-style: normal;
          font-weight: normal;
        }
      }
    }
  }
}