$ki-home: "\e949";
$ki-check: "\e94a";
$ki-phone: "\e94b";
$ki-euro: "\e94c";
$ki-terapist-phy: "\e94d";
$ki-director: "\e943";
$ki-doctor-phy: "\e944";
$ki-equipe: "\e945";
$ki-health-director: "\e946";
$ki-healthcare-principal: "\e947";
$ki-healthcare: "\e948";
$ki-internal-menu: "\e955";
$ki-terapie-animazione: "\e900";
$ki-terapie-attivita-in-giardino: "\e901";
$ki-terapie-attivita-sportiva: "\e902";
$ki-terapie-auto: "\e903";
$ki-terapie-autosufficienti: "\e904";
$ki-terapie-cineforum: "\e905";
$ki-terapie-clownterapia: "\e906";
$ki-terapie-compleanno: "\e907";
$ki-terapie-cucina: "\e908";
$ki-terapie-elioterapia: "\e909";
$ki-terapie-fotografia: "\e90a";
$ki-terapie-ginnastica-di-gruppo: "\e90b";
$ki-terapie-giochi-di-stimolazione-cognitiva: "\e90c";
$ki-terapie-gruppo-lettura: "\e90d";
$ki-terapie-laboratorio-artistico: "\e90e";
$ki-terapie-lavori-manuali: "\e90f";
$ki-terapie-messa: "\e910";
$ki-terapie-mostre: "\e911";
$ki-terapie-musica-dal-vivo-e-ballo: "\e912";
$ki-terapie-musicoterapia: "\e913";
$ki-terapie-ortoterapia-ortobotanica: "\e914";
$ki-terapie-pet: "\e915";
$ki-terapie-psicologo: "\e916";
$ki-terapie-scarica-file: "\e917";
$ki-terapie-teatro: "\e918";
$ki-terapie-treno: "\e919";
$ki-terapie-universita-della-terza-eta: "\e91a";
$ki-terapie-yoga: "\e91b";
$ki-servizi-sanitari-adi-post-dimissione: "\e91c";
$ki-servizi-sanitari-alzheimer-nucleo: "\e91d";
$ki-servizi-sanitari-autosufficienti: "\e91e";
$ki-servizi-sanitari-centro-dialisi: "\e91f";
$ki-servizi-sanitari-centro-diurno: "\e920";
$ki-servizi-sanitari-cure-domiciliari: "\e921";
$ki-servizi-sanitari-cure-intermedie: "\e922";
$ki-servizi-sanitari-demenza-nucleo-protetto: "\e923";
$ki-servizi-sanitari-demenza: "\e924";
$ki-servizi-sanitari-dentista: "\e925";
$ki-servizi-sanitari-educadore-prof: "\e926";
$ki-servizi-sanitari-guardia-medica-h24: "\e927";
$ki-servizi-sanitari-hospice: "\e928";
$ki-servizi-sanitari-infermiere-h24: "\e929";
$ki-servizi-sanitari-medico-h24: "\e92a";
$ki-servizi-sanitari-non-autosufficienti: "\e92b";
$ki-servizi-sanitari-operatore: "\e92c";
$ki-servizi-sanitari-palestra-fisioterapia: "\e92d";
$ki-servizi-sanitari-palestra: "\e92e";
$ki-servizi-sanitari-parzialmente-autosufficienti: "\e92f";
$ki-servizi-sanitari-polo-diagnostico: "\e930";
$ki-servizi-sanitari-ps-disabili: "\e931";
$ki-servizi-sanitari-ps-gravi-disabilita: "\e932";
$ki-servizi-sanitari-ps-psichiatrici: "\e933";
$ki-servizi-sanitari-riabilitazione-general-geriatrica: "\e934";
$ki-servizi-sanitari-riabilitazione-mantenimento: "\e935";
$ki-servizi-sanitari-riabilitazione-specialistica: "\e936";
$ki-servizi-sanitari-ricoveri-temporanei: "\e937";
$ki-servizi-sanitari-stati-vegetativi: "\e938";
$ki-flag-en: "\e939";
$ki-confort-bar: "\e93a";
$ki-confort-camera-singola: "\e93b";
$ki-confort-cappella: "\e93c";
$ki-confort-estetista: "\e93d";
$ki-confort-giardino: "\e93e";
$ki-confort-giornali: "\e93f";
$ki-confort-parrucchiera: "\e940";
$ki-confort-podologo: "\e941";
$ki-confort-terrazzo: "\e942";