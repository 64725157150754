$blue: #003b77;
$blue-secondary: #1d9bf0;
$blue-light: #e5f4fa;
$blue-sky: #c2e6f9;
$secondary: #006e9c;
$white: #ffffff;
$brown: #494949;
$dark-brown: #949494;
$light-gray: #e8e8e8;
$gray: #687078;
$cerulean: #0098d7;
$ice: #e5f4fa;
$peacock: #006e9c;

$custom-colors: (
  "azure": $blue-secondary,
  "ice": $ice,
  "navy": #001e3b,
  "brown": $brown,
  "dark-brown": $dark-brown,
  "light-gray": $light-gray,
  "cerulean": $cerulean,
  "white": $white,
  "peacock": $peacock,
  "gray": $gray,
  "blue-sky": $blue-sky
);

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// Breadcrumbs
$breadcrumb-font-size:              .75rem;
$breadcrumb-padding-y:              1rem;
$breadcrumb-margin-bottom:          0;
$breadcrumb-divider-color:          $gray;
$breadcrumb-active-color:           $primary;

// Typography
$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base;
$headings-font-weight: 600;
$headings-margin-bottom: $spacer * 1;

$font-family-base: "Open Sans", sans-serif;
$small-font-size: .75rem;

// RFS
$rfs-base-value: .75rem;
$rfs-breakpoint: 1200px; // xl breakpoint

// Links
$link-decoration: none;

// Buttons
$btn-border-radius: 30px;
$btn-border-width: 2px;
$btn-padding-y: 0.862rem;
$btn-padding-x: 1.75rem;

// Cards
$card-height: 100%;
$card-border-radius: .444rem;

// Input
$input-border-color: $brown;
$input-color: $brown;
$input-focus-border-color: $brown;
$input-placeholder-color: $brown;
$form-select-border-color: $brown;
$input-font-size: 1rem;
$input-font-size-lg: 1rem;
$form-select-font-size-lg: 1rem;

$form-label-color: $brown;
$input-border-radius: .5rem;
$input-border-radius-lg: .5rem;
$form-select-border-radius-lg: .5rem;

// Accordion
$accordion-border-width: .5px;
$accordion-border-color: $dark-brown;
$accordion-border-radius: 0;

// Spacing
$enable-negative-margins: true;
$cutom-spacers: (
  6: $spacer * 2.2
);
$spacers: map-merge(
  $spacers,
  $cutom-spacers
);

// Pagination
$pagination-border-color: $blue;
$pagination-border-width: 2px;
$pagination-active-bg: $blue-secondary;
$pagination-active-border-color: $blue-secondary;
$pagination-hover-color: $white;
$pagination-hover-bg: $blue-secondary;
$pagination-hover-border-color: $blue-secondary;

// Custom
$header-height: 104px;
$header-height-sm: 70px;
$top-header-height: 54px;

$quote-color: #CCC;
$header-bg-dark: #1f1f1f; 

$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

.bg-primary-light {
  background-color: $blue-secondary;
}

.bg-blue-light {
  background-color: $blue-light;
}

.rounded-4 {
  border-radius: 1rem;
}
.rounded-5 {
  border-radius: .5rem;
}

.rounded-5-end {
  @include border-end-radius(.5rem)
}

.lh-4 {
  line-height: 1.4 !important;
}

.ratio-2x3 {
  --bs-aspect-ratio: 150%;
}