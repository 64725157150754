.contatti-page {
  .card {
    &-body {
      img {
        width: 60px;
        height: 60px;

        @include media-breakpoint-down(md) {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  [stripe-breadcrumb] {
    border-bottom: .5px solid $dark-brown;
  }

  [stripe-text] {
    h5 {
      @include media-breakpoint-down(md) {
        line-height: 1.5;
      }
    }
  }

  [stripe-form-contact] {
    .content-right {
      .card {
        height: auto;

        &-body {
          @include padding(2.2rem);

          @include media-breakpoint-down(xl) {
            @include padding(1.5rem);
          }
        }

        &-title {
          line-height: 1.33;
        }
      }
    }
  }
}