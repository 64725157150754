footer {
  &[stripe-footer] {
    [stripe-social] {
      img {
        width: 100px;
  
        @include media-breakpoint-down(lg) {
          width: 80px;
        }
      }
    }

    line-height: 1.71;

    p {
      font-size: $small-font-size;
    }

    .footer-h1 {
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 600;
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.5;

      .footer-h1 {
        border-top: .5px solid var(--bs-white);
      }

      .privacy {
        @include font-size(.555rem);
      }
    }
  
    @include media-breakpoint-up(md) {
      .pt-md-3 {
        padding-top: 1.333rem !important;
      }

      .footer-h1 {
        pointer-events: none !important;

        i:not(.ki) { 
          display: none !important;
        }

        .ki-flag-en {
          font-size: .9rem;
        }
      }
  
      .collapse {
        display: block !important;
      }
    }
  
    .footer-h1.collapsed {
      i { transform: rotate(270deg); }
    }
  }

  img.kos-logo {
    width: 44px;
  }
}