.soluzioni-cure-page {
  [stripe-cure-card] {
    img {
      width: 56px;
    }

    @include media-breakpoint-down(md) {
      img {
        width: 45px;
      }

      img, h4 {
        margin-bottom: 0.6rem;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  [stripe-how-works] {
    .how-works-content {
      @include padding(2.2rem);
    }
  }

  [stripe-info] {
    .card-body {
      @include padding(2.2rem);

      img {
        width: 60px;
        height: 60px;
      }

      p {
        margin-bottom: 0;
      }

      .btn {
        @include media-breakpoint-up(md) {
          position: absolute;
        }
      }
    }
  }
}