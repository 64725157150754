.quote {
  &-avatar {
    max-width: 150px;
    max-height: 150px;
    float: right;

    @include media-breakpoint-down(md) {
      max-width: 90px;
      max-height: 90px;
      margin-left: 1.5rem;
      margin-bottom: 1rem;
      float: left;
    }
  }

  &-content {
    border-left: 4px solid var(--bs-azure);
    position: relative;
  
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 15px;
      border-color: transparent transparent transparent var(--bs-azure);
      position: absolute;
      left: 0;
      top: calc(50% - 10px);
    }
  }
}