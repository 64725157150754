.alzheimer-page {
  .cit {
    color: #515151;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .div1 { grid-area: 1 / 1 / 5 / 5; }
  .div2 { grid-area: 1 / 5 / 4 / 8; }
  .div3 { grid-area: 4 / 5 / 6 / 7; }

  @include media-breakpoint-down(md) {
    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }

    .div1 { grid-area: 1 / 1 / 4 / 5; }
    .div2 { grid-area: 4 / 1 / 6 / 3; }
    .div3 { grid-area: 4 / 3 / 6 / 5; }
  }

  [stripe-banner] {
    .bg-gradient {
      background-image: linear-gradient(to bottom, rgba($brown, 0.5), rgba($brown, 0.5)) !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    [class*="col-"] {
      z-index: 10;
    }
  }

  [stripe-text] {
    .li-index {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid $blue-secondary;
      border-radius: 100%;
      color: $blue-secondary;
      text-align: center;
    }

    hr {
      opacity: 1;
      background-color: $primary;
      height: 2px;
    }
  }

  [stripe-evidence] {
    .icon {
      width: 140px;
      height: 140px;
      border: 3px solid $blue-secondary;
      border-radius: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      i { 
        font-size: 3.5rem;
      }

      @include media-breakpoint-down(md) {
        width: 82px;
        height: 82px;

        i {
          font-size: 2.22rem;
        }
      }
    }
  }

  [stripe-keypoints] {
    img {
      width: 80px;
      margin-bottom: 1rem;
    }

    hr { opacity: 1; }

    .hr2 { height: 2px; }
    .hr3 { height: 3px; }
    .hr4 { height: 4px; }
  }
}