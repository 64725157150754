$selector: ".card";

#{$selector} {
  &.shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &-footer {
    background-color: transparent;
    border-top: none;
  }

  &.card-list {
    border: none;

    .list-group {
      padding-top: 1rem;

      &-item {
        border: none;
        padding: $list-group-item-padding-y 0;
        color: $primary;
        @include font-size(1.5rem);
        display: flex;
        align-items: baseline;

        @include media-breakpoint-up(lg) {
          min-height: 100px;
        }
      }
    }

    #{$selector}-header {
      padding: $card-cap-padding-y 0;
      background: $white;
      color: $primary;
      @include font-size(1.1666rem);
      border-bottom: .5px solid $dark-brown;
    }
  }
}

[card-home] {
  #{$selector} {
    &-title {
      margin-bottom: .8rem;
    }
    
    .bg-cover-center {
      padding-bottom: 120%;
    }
  }

  @include media-breakpoint-only(md) {
    #{$selector} {
      padding-bottom: 0;
      height: 350px;

      &-title {
        font-size: 25px;
      }
    }
  }
}

[card-news] {
  #{$selector} {
    &-title, &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &-text {
      -webkit-line-clamp: 4;
    }
  }
}

[card-rsa] {
  &#{$selector} {
    height: auto;

    .image {
      width: 210px;
      height: 100%;
      min-height: 180px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}