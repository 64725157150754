.la-nostra-equipe-page {
  [stripe-keypoints] {
    .ki {
      font-size: 5rem;

      @include media-breakpoint-down(md) {
        font-size: 4rem;
      }
    }

    .separator {
      position: absolute; 
      right: 1rem; 
      top: 0; 
      bottom: 0;
      width: 1px; 
      background-color: $dark-brown;
      opacity: 0.25;
    }
  }
}