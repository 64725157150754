[stripe-internal-menu] {
  .card {
    .accordion {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      display: flex;
      align-items: center;
      height: 64px;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;

      &-header {
        border-top-right-radius: $card-border-radius;
        font-weight: 600;

        &.active {
          font-weight: 600;
          background-color: $blue-sky;
          color: var(--bs-azure);
          border-radius: $card-border-radius;

          i {
            color: var(--bs-azure) !important;
          }
        }
      }

      .icon-flag {
        height: 100%;
        width: 64px;

        .bi::before {
          transition: transform 0.2s ease-in-out;
        }
      }
    
      &.collapsed {
        border-radius: $card-border-radius;

        .icon-flag .bi::before {
          transform: rotate(-180deg);
        }

        .accordion-header {
          border-bottom-right-radius: $card-border-radius;
        }
      }
    }

    .list-group {
      border-top: 1px solid #82A3BF;

      &-item {
        padding-right: 2rem;
      }
    }

    .card-header {
      font-weight: 600;
      padding-right: 2rem;
    }

    .card-header, .list-group-item {
      background-color: $ice;
      color: $primary;
      border: 0;
      position: relative;
      padding-top: 0.888rem;
      padding-bottom: 0.888rem;
  
      &.active, &:hover {
        font-weight: 600;
        background-color: $blue-sky;
        color: var(--bs-azure);
        border-radius: $card-border-radius;

        i {
          color: var(--bs-azure) !important;
        }
      }

      &.active {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: var(--bs-azure);
          position: absolute;
          right: 1rem;
          border-radius: 50%;
          top: calc(50% - 5px);
        }
      }
    }
  }
}