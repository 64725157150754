.news-page {
  [stripe-breadcrumb] {
    border-bottom: .5px solid $dark-brown;
  }

  [stripe-main-article] {
    .card {
      &-body {
        @include padding(1.77rem);
      }

      .ratio {
        @include border-end-radius(.5rem);
        
        @include media-breakpoint-down(lg) {
          @include border-top-radius(.5rem);
          border-bottom-right-radius: 0;
        }
      }
    }

    small {
      padding-bottom: 0.77rem;
    }

    .card-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  [stripe-news] {
    .card {
      &-body {
        @include padding(1.77rem);
      }

      &-footer {
        @include padding(1.77rem);
      }
    }

    .pagination {
      justify-content: center;

      .page-item {
        width: 60px;
        height: 60px;
        margin-right: calc(var(--bs-gutter-x) * 0.5);
        margin-left: calc(var(--bs-gutter-x) * 0.5);

        @include media-breakpoint-down(lg) {
          width: 48px;
          height: 48px;
        }

        .page-link {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.news-content-page {
  [stripe-breadcrumb] {
    border-bottom: .5px solid $dark-brown;
  }

  [stripe-share] {
    li img {
      cursor: pointer;
      width: 21px;
      height: auto;
    }
  }
}