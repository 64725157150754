.home-page {
  [stripe-two-col-component] {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $black;
      opacity: .4;
    }
  }

  [stripe-quote] {
    .flickity {
      &-prev-next-button {
        color: $primary;
      }

      &-page-dots {
        bottom: -25px;
      }
    }
  }

  [stripe-grid] {
    .row-title {
      .logo-anni {
        height: 64px;

        @include media-breakpoint-down(md) {
          height: 48px;
        }
      }

      .vertical-divider {
        width: 1px;
        height: 100%;
        border: solid .5px $dark-brown;
      }

      .title {
        @include media-breakpoint-down(md) {
          font-size: 1.5rem !important;
        }
      }
    }

    .row-data {
      .col-12 {
        &:nth-of-type(1) {
          .divider {
            border-top: solid 2.5px $primary;
          }
        }

        &:nth-of-type(2) {
          .divider {
            border-top: solid 4.5px $peacock;
          }
        }

        &:nth-of-type(3) {
          .divider {
            border-top: solid 6.5px $blue-secondary;
          }
        }

        @include media-breakpoint-down(md) {
          p {
            font-size: 1.5rem !important;
            border-left: solid 2.5px $primary;
            padding-left: 1rem;
          }

          .divider {
            display: none;
          }

          &:nth-of-type(2) {
            p {
              border-color: $peacock;
            }
          }

          &:nth-of-type(3) {
            p {
              border-color: $blue-secondary;
            }
          }
        }
      }
    }

    .parent {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;

      @include media-breakpoint-up(xl) {
        .div1 { grid-area: 1 / 1 / 3 / 5; }
        .div2 { grid-area: 1 / 5 / 2 / 7; }
        .div3 { grid-area: 2 / 5 / 4 / 7; }
        .div4 { grid-area: 4 / 4 / 5 / 7; }
        .div5 { grid-area: 3 / 4 / 4 / 5; }
        .div6 { grid-area: 3 / 1 / 5 / 4; }
      }

      @include media-breakpoint-between(md, xl) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(6, 1fr);

        .div1 { grid-area: 1 / 1 / 3 / 6; }
        .div2 { grid-area: 2 / 6 / 4 / 9; }
        .div3 { grid-area: 4 / 6 / 6 / 9; }
        .div4 { 
          grid-area: 6 / 6 / 7 / 9;

          p {
            @include font-size($font-size-base * 1.25)
          }
        }
        .div5 { grid-area: 5 / 3 / 7 / 6; }
        .div6 { grid-area: 3 / 1 / 5 / 6; }
      }

      div {
        border-radius: $card-border-radius;
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 210px) auto 210px;
        
        .div1 { order: 1; }
        .div2 { order: 3; }
        .div3 { order: 2; }
        .div4 { 
          order: 5; 
          p {
            @include font-size($font-size-base * 1.25)
          }
        }
        .div5 { order: 6; }
        .div6 { order: 4; }
      }
    }
  }

  [stripe-help] {
    input {
      border-radius: 30px;
      padding: 0.862rem 1.75rem;
      border-color: $light-gray;
    }
    
    @include media-breakpoint-down(lg) {
      .input-group {
        input {
          padding: 0.862rem 1rem;
        }

        .form-control {
          border-right: 0;

          &:focus {
            box-shadow: none;
            border-color: $light-gray;
          }
        }

        .input-group-text {
          border-color: $light-gray;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
      }
    }
  }

  [stripe-covid] {
    img {
      width: 80px;
      height: 80px;
    }

    @include media-breakpoint-down(lg) {
      img {
        display: block;
        width: 60px;
        height: 60px;
      }
    }
  }

  [stripe-numbers] {
    img {
      width: 80px;
    }

    @include media-breakpoint-down(lg) {
      .number-container {
        width: 50%;
      }
    }
  }

  &.en {
    [stripe-residences] {
      .separator {
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: $dark-brown;
        opacity: 0.25;
      }

      i.ki {
        font-size: 6rem;

        @include media-breakpoint-down(md) {
          font-size: 5rem;
        }
      }

      .map-container #google-map-container {
        max-height: 500px;
      }
    }

    [stripe-keypoints] {
      img {
        height: 80px;
        width: auto;
        margin-bottom: 1rem;
      }
  
      hr { opacity: 1; }
  
      .hr2 { height: 2px; }
      .hr3 { height: 3px; }
      .hr4 { height: 4px; }
    }

    [stripe-cure-card] {
      img {
        height: 56px;
        width: fit-content;
      }
  
      @include media-breakpoint-down(md) {
        img {
          width: 45px;
        }
  
        img, h4 {
          margin-bottom: 0.6rem;
        }
      }
  
      p {
        margin-bottom: 0;
      }
    }
  }
}