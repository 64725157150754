[stripe-one-col-media-component] {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0.1) 80%);
  }

  .content-container {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    min-height: 55vh;

    @include media-breakpoint-down(lg) {
      min-height: 35vh;
    }

    @include media-breakpoint-down(sm) {
      min-height: 45vh;
    }
  }
}